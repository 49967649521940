import React, { useEffect } from "react";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import Jay from "./pages/jay";
import "@fontsource/manrope";
import Layout1 from "./layouts/Layout1";
import Layout2 from "./layouts/Layout2";
import CloudServices from "./pages/CloudServices";
import Security from "./pages/Security";
import WhatsCloud from "./pages/whatsCloud";
import PaaS from "./pages/paas";
import SaaS from "./pages/saas";
import Containerize from "./pages/Containerize";
import DevOps from "./pages/DevOps";
import Compliance from "./pages/Compliance";
import Consulting from "./pages/Consulting";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Partners from "./pages/Partners";
import CaseStudies from "./pages/CaseStudies";
import FintechCloudMigration from "./pages/FintechCloudMigration";
import FintechEKS from "./pages/FintechEKS";
import CyberEngineering from "./pages/CyberEngineering";
import Blog from "./pages/Blog";
import Careers from "./pages/Careers";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout1 />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />{" "}
          <Route path="/partners" element={<Partners />} />{" "}
          <Route path="/blog" element={<Blog />} />{" "}
          <Route path="/careers" element={<Careers />} />{" "}
          <Route path="/cyberengineering" element={<CyberEngineering />} />{" "}
          <Route path="/cloud" element={<CloudServices />} />{" "}
          <Route path="/devops" element={<DevOps />} />{" "}
          <Route path="/security" element={<Security />} />{" "}
          <Route path="/services" element={<Services />} />{" "}
          <Route path="/compliance" element={<Compliance />} />{" "}
          <Route path="/Consulting" element={<Consulting />} />{" "}
          <Route path="/contact" element={<ContactUs />} />{" "}
          <Route path="/whatsCloud" element={<WhatsCloud />} />{" "}
          <Route path="/paas" element={<PaaS />} />{" "}
          <Route path="/saas" element={<SaaS />} />
          <Route path="/containerize" element={<Containerize />} />{" "}
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />{" "}
          <Route path="/casestudies" element={<CaseStudies />} />{" "}
          <Route path="/fintechcm" element={<FintechCloudMigration />} />{" "}
          <Route path="/fintecheks" element={<FintechEKS />} />{" "}
          <Route path="*" element={<Navigate to="/" />} />{" "}
        </Route>
        <Route path="/qr" element={<Layout2 />}>
          <Route path="jjv" element={<Jay />} />
        </Route>
      </Routes>{" "}
    </>
  );
};

export default App;
